/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { OpenAI } from 'openai';
import Papa from 'papaparse';
import csvdata from "../assets/transcript-gpt.csv";
import image from "../assets/gpt-bg.jpg";
import './styles.css';

var openai = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: true,
});

const Answer = () => {
    const [userQuestion, setUserQuestion] = useState('');
    const [assistantReply, setAssistantReply] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [questionAnswerPairs, setQuestionAnswerPairs] = useState([]);

    useEffect(() => {
        const fetchCsvData = async () => {
            try {
                const response = await fetch(csvdata);
                const csvString = await response.text();
                const { data } = Papa.parse(csvString, { header: true });
                setCsvData(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchCsvData();
    }, []);

    const searchRelevantInfo = (userQuestion, csvData) => {
        if (!csvData || csvData.length === 0) {
            console.log('CSV data not available.');
            return '';
        }

        for (let row of csvData) {
            if (row.transcripts && userQuestion.toLowerCase().includes(row.transcripts.toLowerCase())) {
                return `Transcript: ${row.transcript}`;
            }
        }
        return '';
    };


    const handleUserQuestionChange = (e) => {
        setUserQuestion(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const relevantInfo = searchRelevantInfo(userQuestion, csvData);

        if (relevantInfo) {
            setAssistantReply(prevReplies => [...prevReplies, relevantInfo]);
            setQuestionAnswerPairs(prevPairs => [...prevPairs, { question: userQuestion, answer: relevantInfo }]);
        } else {
            const messages = [
                { role: 'system', content: 'You are a helpful assistant.' },
                { role: 'user', content: `User: ${userQuestion}\nCSV Data: ${JSON.stringify(csvData)}` },
            ];

            try {
                const chatCompletion = await openai.chat.completions.create({
                    model: 'gpt-3.5-turbo',
                    messages,
                });

                const assistantReply = chatCompletion.choices[0].message.content;
                setAssistantReply(prevReplies => [...prevReplies, assistantReply]);
                setQuestionAnswerPairs(prevPairs => [...prevPairs, { question: userQuestion, answer: assistantReply }]);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        setUserQuestion('');
    };

    return (
        <section class="flex flex-col items-center justify-center h-screen bg-gray-900 text-gray-800 p-2">

            <div class="flex flex-col flex-grow w-full max-w-4xl bg-transparent rounded-lg overflow-hidden">
                <div class="flex flex-col flex-grow h-0 p-4 overflow-auto">
                    {questionAnswerPairs.map((reply, index) => (
                        <div key={index}>
                            <div class="flex w-full mt-2 space-x-3 max-w-xs">
                                <div>
                                    <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                                        <p class="text-sm">User: {reply.question}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex w-full mt-2 space-x-3 max-w-md ml-auto justify-end">
                                <div class="bg-slate-700 text-white p-3 rounded-l-lg rounded-br-lg">
                                    <p class="text-sm">Assistant: {reply.answer}</p>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>

                <div class="bg-gray-700 p-4 rounded-lg">
                    <form onSubmit={handleSubmit} className="flex flex-col h-full max-w-4xl mx-auto w-full bg-gray-800 p-4">
                        <label className="block text-sm font-medium text-gray-400 mt-2" htmlFor="userQuestion">Ask a question:</label>
                        <div className="mt-2 flex-grow ">
                            <input type="text" id="userQuestion" className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2" value={userQuestion} onChange={handleUserQuestionChange} />
                        </div>
                        <button type="submit" className="mt-2 px-4 py-1 bg-blue-600 border border-transparent rounded-md font-semibold text-white uppercase tracking-widest focus:outline-none focus:border-indigo-700 focus:ring focus:ring-indigo-200 active:bg-indigo-700 transition ease-in-out duration-150 w-fit">Send</button>
                    </form>
                </div>
            </div>

        </section>

    );
};

export default Answer;


