import React from 'react'
import Navbar from '../components/Navbar'
import Answer from '../components/Answer'

function AnswerPage() {
    return (
        <div>
            <Navbar />
            <Answer />
        </div>
    )
}

export default AnswerPage