import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import AnswerPage from "../src/pages/AnswerPage";

function App() {
  return (
    <div className=''>

      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/answer-generate' element={<AnswerPage />} />
      </Routes>
    </div>
  );
}

export default App;
